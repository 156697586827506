body {
  margin: 0;
}

header {
  background-color: #fff;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1;
  border: 1px solid gainsboro;
}

main {
  padding: 10px 20px;
}

button[class*="acCloseButton"] {
  display: none;
}

#agenda-container {
  position: absolute;
  bottom: -50px;
  margin-top: 20px;
  width: 100%;
  background-color: #fff;
  border: 1px solid gainsboro;
  padding: 10px;
}

div[class*="acWidgetContainer"] {
  z-index: 1;
}

div[class*="acWidgetContainer"] {
  right: 50%;
  bottom: min(10vh, 10vw);
  width: 50vw;
  height: 40vh;
}

div[class*="acFrameContainer"].show {
  width: 100%;
  height: 60vh !important;
  margin-left: 50%;
  margin-top: -35vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  button[class*="acCloseButton"] {
    display: none;
  }
  position: relative !important;
  overflow: visible;
}

button[class*="acButtonStyles"] {
  width: 70px;
  height: 70px;
  position: absolute;
  margin-left: 100%;
}

#agenda-container {
  bottom: -60px;
  border-radius: 5px;
  padding: 10px;
}

div[class*="acFrameContainer"].show {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: visible;
}

/*
 * https://github.com/kevinsqi/react-circular-progressbar/blob/master/src/styles.css
 */

.CircularProgressbar {
  width: 100%;
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

.small-text {
  font-size: 0.9em !important;
}

a:focus {
  outline: 1px solid #1B75BB;
  outline-offset: 1px;
}
