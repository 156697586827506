.video-wrapper {
  position: relative !important;

  video.facing-mode-user {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.camera-markers {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 75%;
  width: 75%;
}

.camera-wrapper {
  position: relative;
  // camera hight is realative to how wide it is
  margin: 0 5%;
}

.marker {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-width: 0;
  border-style: solid;
  border-color: rgba(224, 25, 25, 0.7);
}

.lt {
  left: 0;
  top: 0;
  border-left-width: 6px;
  border-top-width: 6px;
}

.rt {
  right: 0;
  top: 0;
  border-right-width: 6px;
  border-top-width: 6px;
}

.lb {
  left: 0;
  bottom: 0;
  border-left-width: 6px;
  border-bottom-width: 6px;
}

.rb {
  right: 0;
  bottom: 0;
  border-right-width: 6px;
  border-bottom-width: 6px;
}
